@use 'dawuti.config' as dawuti;
@use 'theme/mixins' as mixins;
:root {
  --button-height: 40px;
  --button-small-height: 27px;
  --buttons-font-size: var(--font-size-small);
  --buttons-color: var(--color-primary);
}

a.button,
button.button {
  // Primary
  cursor: pointer;

  width: fit-content;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: var(--button-height);
  padding: 0 calc(var(--s) * 3);

  font-family: var(--font-primary);
  font-size: var(--buttons-font-size);
  line-height: var(--button-height);
  font-weight: 500;
  text-decoration: none;
  letter-spacing: var(--letter-spacing-narrow);

  background-color: var(--color-primary);
  color: var(--color-light);

  border: none;
  border-radius: var(--button-height);

  > span {
    color: inherit;
  }

  @include mixins.defaultTransition();

  .dynicon {
    --color: var(--color-light);
    --size: 18px;
    margin-left: calc(-1 * var(--s));
    margin-right: var(--s);
  }

  span + .dynicon {
    margin-left: var(--s);
    margin-right: calc(-1 * var(--s));
  }

  &:hover {
    background-color: var(--color-button-hover);
  }

  &:focus {
    background-color: var(--color-button-focus);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.23;
    .dynicon {
      opacity: 0.23;
    }
  }

  // Outline
  &.button--outline {
    border: var(--card-border);
    background-color: var(--color-light);
    // TODO talk with Lorea
    color: var(--color-primary);

    .dynicon {
      --color: var(--color-primary);
    }

    &:hover {
      background-color: var(--color-button-outline-hover);
      border: 1px solid var(--color-neutral-variant-50);
    }

    &:focus {
      background-color: var(--color-button-outline-focus);
      border: 1px solid var(--color-primary);
    }
  }

  // Card
  &.button--card {
    border: var(--card-border);
    background-color: var(--color-light);
    border-radius: var(--card-border-radius);
    padding: calc(var(--s) * 2);
    height: auto;
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    width: 100%;
    justify-content: flex-start;
    color: var(--color-text);
    .dynicon {
      --color: var(--color-primary);
      margin: 0;
    }

    &:hover {
      background-color: var(--color-button-outline-hover);
      border: 1px solid var(--color-neutral-variant-50);
    }

    &:focus {
      background-color: var(--color-button-outline-focus);
      border: 1px solid var(--color-primary);
    }
  }

  &.button--card-emphasys {
    border: var(--card-border);
    background-color: var(--color-neutral-variant-90);
    border-radius: var(--card-border-radius);
    padding: calc(var(--s) * 2);
    height: auto;
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    width: 100%;
    justify-content: flex-start;
    color: var(--color-text);
    .dynicon {
      --color: var(--color-primary);
      margin: 0;
    }

    &:hover {
      background-color: var(--color-button-card-emphasys-hover);
      border: 1px solid var(--color-neutral-variant-50);
    }

    &:focus {
      background-color: var(--color-button-card-emphasys-focus);
      border: 1px solid var(--color-primary);
    }
  }

  &.button--clear {
    background-color: transparent;
    color: var(--color-primary);

    .dynicon {
      --color: var(--color-primary);
    }

    &:hover {
      background-color: var(--color-button-outline-hover);
    }

    &:focus {
      background-color: var(--color-button-outline-focus);
    }
  }

  &.button--clear-neutral {
    background-color: transparent;
    color: var(--color-text);

    .dynicon {
      --color: var(--color-text);
    }

    &:hover {
      background-color: var(--color-button-outline-hover);
    }

    &:focus {
      background-color: var(--color-button-outline-focus);
    }
  }

  &.button--invisible {
    padding: 0;
    background-color: transparent;
    color: inherit;
    border-radius: 0;
  }

  &.button--elevated {
    background-color: var(--color-neutral-99);
    color: var(--color-primary);
    @include mixins.shadow-elevation-1;

    .dynicon {
      --color: var(--color-primary);
    }

    &:hover {
      background-color: var(--color-button-outline-hover);
    }

    &:focus {
      background-color: var(--color-button-outline-focus);
    }
  }

  &.button--secondary {
    background-color: var(--color-secondary-90);
    color: var(--color-text);

    .dynicon {
      --color: var(--color-text);
    }

    &:hover {
      background-color: var(--color-button-secondary-hover);
    }

    &:focus {
      background-color: var(--color-button-secondary-focus);
    }

    &.button--dark {
      background-color: var(--color-secondary-30);
      color: var(--color-light);

      .dynicon {
        --color: var(--color-light);
      }
    }
  }

  &.button--tertiary {
    background-color: var(--color-tertiary-70);
    color: var(--color-light);

    .dynicon {
      --color: var(--color-light);
    }

    &:hover {
      background-color: var(--color-button-tertiary-hover);
    }

    &:focus {
      background-color: var(--color-button-tertiary-focus);
    }
  }

  &.button--icon {
    padding: 0;
    aspect-ratio: 1/1;
    .dynicon {
      margin: 0;
    }
  }

  &.button--tab {
    background-color: transparent;
    color: var(--color-text);

    &.button--selected {
      background-color: var(--color-neutral-variant-90);
      color: var(--color-text);
    }
    &:hover {
      background-color: var(--color-neutral-variant-90);
      color: var(--color-text);
    }
  }

  &.button--link {
    border: none;
    background: none;
    color: var(--color-primary);
    text-align: center;
    padding: calc(var(--s) * 1.25) calc(var(--s) * 1.5);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);

    > * {
      color: var(--color-primary);
    }

    &:disabled {
      opacity: 0.5;
    }

    .dynicon {
      --color: var(--color-primary);
      margin-right: var(--s);
    }
  }

  &.button--selector {
    padding: calc(var(--s) * 0.75) calc(var(--s) * 2);
    height: calc(var(--s) * 4);
    background-color: transparent;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    font-weight: 500;

    border: 1px solid var(--color-neutral-variant-50);
    border-radius: var(--s);
    font-family: var(--font-primary);
    color: var(--color-neutral-variant-30);

    cursor: pointer;

    @include dawuti.mq-md {
      margin-bottom: 0;
    }

    .dynicon {
      --color: var(--color-text);
      &:first-child {
        margin-left: calc(-1 * var(--s));
        margin-right: var(--s);
      }
      &:last-child {
        margin-left: var(--s);
        margin-right: calc(-1 * var(--s));
      }
    }

    .text {
      color: var(--color-neutral-variant-30);
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  &.button--icon-close {
    background: transparent;
    padding: 0;
    margin: 0;
    margin-left: auto;
    aspect-ratio: 1/1;
    height: calc(var(--s) * 3);
    .dynicon {
      margin: 0;
      --color: var(--color-text);
      --size: calc(var(--s) * 3);

      &:hover {
        @include mixins.defaultTransition();
        --color: var(--color-neutral-variant-60);
      }
    }
  }

  &.button--suffix {
    margin-right: var(--s);
  }

  &.button--small {
    height: var(--button-small-height);
    padding: 0 calc(var(--s) * 2);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    span {
      font-size: var(--font-size-small);
      line-height: var(--line-height-small);
    }
    .dynicon {
      --size: 12px;
      margin-right: calc(var(--s) / 2);
    }
  }
}

.button--dropdown {
  width: 100%;
  height: 48px;
  border-radius: 0;
  border: none;
  background-color: white;
  cursor: pointer;
  text-align: left;
  padding: 0 calc(var(--s) * 2);
  font-size: calc(var(--s) * 2);
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--mat-option-hover-state-layer-color);
  }

  .dynicon {
    margin-left: var(--s);
  }
}
