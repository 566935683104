mat-form-field {
  &.mat-form-field-appearance-fill {
    border-radius: var(--form-field-border-radius);
    .mat-mdc-text-field-wrapper {
      background-color: var(--color-primary-95);
      &.mdc-text-field--disabled {
        background-color: var(--color-neutral-variant-95);
      }
    }
    .mat-mdc-form-field-flex {

      .mat-select-arrow-wrapper {
        transform: translateY(0);
      }
      textarea {
        min-height: 200px;
      }
    }

    .mat-mdc-form-field-label {
      color: var(--color-neutral-variant-30);
    }
    .mat-mdc-form-field-wrapper {
      margin-bottom: 10px;
    }
  }
  &.mat-form-field-appearance-fill.transparent {
    .mat-mdc-text-field-wrapper {
      background-color: transparent;
    }
  }
}

.mat-mdc-select-arrow {
  width: 24px !important;
  height: 24px !important;
}

fieldset {
  border: 0;
  padding: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
  // text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-hidden-wrapper {
  input {
    position: absolute;
    opacity: 0;
  }
}

.input {
  border: none;
  border-radius: var(--form-field-border-radius);
  border-bottom: 1px solid black;
  background-color: var(--color-primary-95);
  color: var(--color-text-variant);
  min-height: calc(var(--s) * 7);
  &.input--input-hidden {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
}
